<template>
    <Home />
</template>


<script>
import Home from './Home.vue'

export default {
    name: 'Prueba',
    components:{
        Home
    }
}
</script>

<style lang="scss" scoped>
.contendor{
    width: 100%;
    background-color: #171717;
    
}


</style>    